<template>
  <div class="loading-skeleton">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="card-header p-0">
              <label>headerheaderheaderheaderheaderheaderheader</label>
              <div class="card-toolbar">
                <a href="#" class="btn btn-sm btn-primary me-2">Go somewhere</a>
              </div>
            </div>
            <div class="row d-flex justify-content-center mb-4">
              <div class="col-sm-12">
                <h5 style="height: 450px" class="card-title mt-10">
                  Card title
                </h5>
              </div>
              <!-- pagination -->
              <div class="mt-5">
                <hr class="mb-10" style="color: LightGrey" />

                <ul class="pagination pagination-sm">
                  <li class="me-2 pt-3">
                    <a class="me-5 ms-5"
                      ><i class="bi bi-caret-left-fill"></i
                    ></a>
                  </li>
                  <li v-for="n in 4" :key="n">
                    <a class="me-14 ms-14'"> </a>
                  </li>
                  <li class="ms-2 pt-3">
                    <a class="me-5 ms-5"
                      ><i class="bi bi-caret-right-fill"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <!-- pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../assets/sass/loader.scss";
</style>
